import React from "react"
import { Helmet } from "react-helmet-async"
import { graphql } from "gatsby"
import Layout from "./layout"
import EmailSignUp from "./EmailSignUp"
import HymnListItem from "./HymnListItem"

export default (props) => {
  const meter = props.pageContext.meter;
  const nodes = props.data.allMarkdownRemark.nodes;

  function getItemModel(node) {
    return {
      href: node.fields.slug,
      title: node.frontmatter.title,
      addedOn: new Date(node.frontmatter.addedOn)
    }
  }

  const hymnListItems = nodes.sort((a, b) => { return a.frontmatter.title.localeCompare(b.frontmatter.title); })
    .map(node => {
      const model = getItemModel(node);
      return <HymnListItem key={model.href} model={model} />
    });

  return (
    <Layout>
      <Helmet>
        <title>{meter}</title>
      </Helmet>
      <div id="home-page-hymn-lists" className="container">
        <div className="item">
          <h1>Meter: {meter}</h1>
          <ul>
            {hymnListItems}
          </ul>
        </div>
      </div>

      <div style={{marginTop: `3em`}}>
        <EmailSignUp />
      </div>
    </Layout>
  )
}

export const query = graphql`
query ($meter: String!) {
  allMarkdownRemark(filter: { frontmatter: { meter: { eq: $meter }}}) {
      nodes {
        frontmatter {
          title
        }
        fields {
          slug
        }
      }
    }
  }
`
