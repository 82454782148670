import React from "react"
import { Link } from "gatsby"

class HymnListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {daysOld: 9999};
  }

  componentDidMount() {
    function toUTC(date) {
      return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
    }

    const addedOn = this.props.model.addedOn;
    if (!addedOn) {
      return;
    }
    const addedOnUtc = toUTC(addedOn);
    const nowUtc = toUTC(new Date());

    const MS_PER_DAY = 1000 * 60 * 60 * 24;

    const daysOld = Math.floor((nowUtc - addedOnUtc) / MS_PER_DAY) - 1;

    var newnessDescription = '';

    if (daysOld === 0) {
      newnessDescription = 'added today';
    } else if (daysOld === 1) {
      newnessDescription = 'added yesterday';
    } else {
      newnessDescription = `added ${daysOld} days ago`;
    }

    this.setState({
      daysOld,
      newnessDescription
    });
  }

  render() {
    return (
      <li>
        <Link to={this.props.model.href}>{this.props.model.title}</Link>{this.props.model.tuneEmojis ? " " + this.props.model.tuneEmojis : ""}<span className="new-badge" style={{ display: this.state.daysOld <= 10 ? 'inline' : 'none', opacity: 0.3 + 0.7*((10 - this.state.daysOld)/10) }} title={this.state.newnessDescription}><span className="paren">(</span>new<span className="paren">)</span></span>
      </li>
    );
  }
}

export default HymnListItem
